// Auth Dispatch States
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const AUTHENTICATED_SUCCESS = "AUTHENTICATED_SUCCESS";
export const AUTHENTICATED_FAIL = "AUTHENTICATED_FAIL";
export const REFRESH_SUCCESS = "REFRESH_SUCCESS";
export const REFRESH_FAIL = "REFRESH_FAIL";
export const USER_LOADED_SUCCESS = "USER_LOADED_SUCCESS";
export const USER_LOADED_FAIL = "USER_LOADED_FAIL";
export const ACTIVATION_SUCCESS = "ACTIVATION_SUCCESS";
export const ACTIVATION_FAIL = "ACTIVATION_FAIL";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_CONFIRM_SUCCESS = "RESET_PASSWORD_CONFIRM_SUCCESS";
export const RESET_PASSWORD_CONFIRM_FAIL = "RESET_PASSWORD_CONFIRM_FAIL";
export const SET_AUTH_LOADING = "SET_AUTH_LOADING";
export const REMOVE_AUTH_LOADING = "REMOVE_AUTH_LOADING";
export const LOGOUT = "LOGOUT";

//Locations
export const GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS";
export const GET_LOCATIONS_FAIL = "GET_LOCATIONS_FAIL";

//Parkings
export const GET_PARKINGS_SUCCESS = "GET_PARKINGS_SUCCESS";
export const GET_PARKINGS_FAIL = "GET_PARKINGS_FAIL";

// Transactions
export const GET_USER_TRANSACTIONS_SUCCESS = "GET_USER_TRANSACTIONS_SUCCESS";
export const GET_USER_TRANSACTIONS_FAIL = "GET_USER_TRANSACTIONS_FAIL";
export const GET_OWNER_DAILY_TRANSACTIONS_SUCCESS =
  "GET_OWNER_DAILY_TRANSACTIONS_SUCCESS";
export const GET_OWNER_DAILY_TRANSACTIONS_FAIL =
  "GET_OWNER_DAILY_TRANSACTIONS_FAIL";

export const STORAGE_PRODUCTS_CART = "slotsCart";

//Alerts
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

// NEW!. Profile Dispatch States
export const LOAD_USER_PROFILE_SUCCESS = "LOAD_USER_PROFILE_SUCCESS";
export const LOAD_USER_PROFILE_FAIL = "LOAD_USER_PROFILE_FAIL";
export const UPDATE_USER_PROFILE_SUCCESS = "LOAD_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAIL = "LOAD_USER_PROFILE_FAIL";
