import React from "react";
import { useHistory } from "react-router-dom";

import Cart from "./Cart/Cart";
import Alert from "../../Alert";

// shopping cart styles
import "./_cartnavbar.scss";

const CartNavbar = (props) => {
  const { slotsCart, getSlotsCart, parkingSlot, rentPrice, timeDifference } =
    props;

  const history = useHistory();

  const HomeChange = () => {
    let path = "/";
    history.push(path);
  };

  return (
    <>
      <ul className="navbar-nav ml-auto mt-2 mt-lg-0 navbar-dark bg-dark">
        <li>
          <button
            className="nav-link btn btn-info btn-sm text-light"
            onClick={HomeChange}
          >
            Volver al Mapa
          </button>
          <Cart
            slotsCart={slotsCart}
            getSlotsCart={getSlotsCart}
            parkingSlot={parkingSlot}
            rentPrice={rentPrice}
            timeDifference={timeDifference}
          />
        </li>
      </ul>
      <Alert />
    </>
  );
};

export default CartNavbar;
