import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import NotFound from "./components/NotFound";

import Layout from "./hocs/Layout";
import PrivateRoute from "./hocs/PrivateRoute";

import { Provider } from "react-redux";
import store from "./store";

import Home from "./containers/Home/Home";
import Login from "./containers/Login";
import OwnerMenu from "./containers/OwnerMenu";
import CustomerMenu from "./containers/CustomerMenu";
import Contact from "./containers/Contact";
import Pqrs from "./containers/Pqrs";

import ShoppingCart from "./components/ShoppingCart/ShoppingCart";
import RedirectPage from "./containers/RedirectPage";

// import Activate from "./containers/Activate";
import ResetPassword from "./containers/ResetPassword";
import ResetPasswordConfirm from "./containers/ResetPasswordConfirm";

const App = () => (
  // configuration of the pages that make up the app built in react

  <Provider store={store}>
    <Router>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/pqrs" component={Pqrs} />
          <Route exact path="/redirect/:id/check" component={RedirectPage} />
          <Route exact path="/reset_password" component={ResetPassword} />
          <Route
            exact
            path="/password/reset/confirm/:uid/:token"
            component={ResetPasswordConfirm}
          />
          <PrivateRoute
            exact
            path="/shoppingcart/:slug/:startime/:endtime/:timedifference/:user"
            component={ShoppingCart}
          />
          <PrivateRoute exact path="/customermenu" component={CustomerMenu} />
          <PrivateRoute exact path="/ownermenu" component={OwnerMenu} />
          {/* <Route exact path="/activate/:uid/:token" component={Activate} /> */}
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </Router>
  </Provider>
);

export default App;
