import React from "react";

const SlotCard = ({
  parkingSlot,
  startime,
  endtime,
  timeDifference,
  price,
  addSlotCart,
}) => {
  // const SlotCard = (props) => {
  /*
  returns the information of each slot available in the parking lot selected within the customer session
  */
  let maxtime = "";
  const PriceByDefault = price;
  // let PriceByDefault = props.price * Math.ceil(props.maxtime / 15);
  if (timeDifference / 60 < 1) {
    // if (props.maxtime / 60 < 1) {
    /*
    examines the time difference in minutes between the start time and the end time of the slot rental defined by the user.
    Here it returns the time in minutes if it is less than one hour
    */
    maxtime = timeDifference + " minutos";
    // maxtime = props.maxtime + " minutos";
  } else {
    if (timeDifference % 60 === 0) {
      // if (props.maxtime % 60 === 0) {
      /*
      Here it returns the time in hours if the minutes is exactly a multiple of an hour
      */
      maxtime = Math.floor(timeDifference / 60) + " horas";
      // maxtime = Math.floor(props.maxtime / 60) + " horas";
    } else {
      /*
      Here it returns the time in hours and minutes if it is more than one hour
      */
      maxtime =
        Math.floor(timeDifference / 60) +
        // Math.floor(props.maxtime / 60) +
        " horas, " +
        (timeDifference % 60) +
        // (props.maxtime % 60) +
        " minutos";
    }
  }
  return (
    <div className="card text-center">
      <div className="card-body">
        {/* <p className="card-title">Espacio: {props.name}</p>
        <p className="card-text">Tamaño: {props.type}</p>
        <h5 className="card-text">
          Precio de renta a 15 minutos: ${props.price}
        </h5> */}
        <p className="card-title">Parqueadero {parkingSlot.prkg_name}</p>
        <p className="card-text">Dirección: {parkingSlot.prkg_address}</p>
        <p className="card-text">Detalle: {parkingSlot.prkg_more_info}</p>
        <p className="card-text">
          Periodo: {startime} - {endtime}
        </p>
        <h5 className="card-text">
          Tiempo seleccionado: {maxtime === "" ? (maxtime = "15") : maxtime}
        </h5>
        <p className="card-text">Precio de alquiler: ${PriceByDefault}</p>
        <button
          onClick={() => addSlotCart(parkingSlot.id, parkingSlot.prkg_name)}
          // onClick={() => props.addSlotCart(props.id, props.name)}
          className="btn btn-primary"
        >
          Añadir al carrito
        </button>
      </div>
    </div>
  );
};

export default SlotCard;
