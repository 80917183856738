import { GET_PARKINGS_SUCCESS, GET_PARKINGS_FAIL } from "../actions/types";

const initialState = {
  parkings: null,
};

export default function parkings(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PARKINGS_SUCCESS:
      return {
        ...state,
        parkings: payload,
      };
    case GET_PARKINGS_FAIL:
      return {
        ...state,
        parkings: null,
      };
    default:
      return state;
  }
}
