import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

/*
library that allows configuring the description of the current 
visible page in the browser
*/
import { Helmet } from "react-helmet";

// library that generates alerts within the shopping cart
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// library that allows you to build requests to the database
import axios from "axios";

import CartNavbar from "./CartNavbar/CartNavbar";
// import Pagination from "../components/Slots/Pagination";
import { STORAGE_PRODUCTS_CART } from "../../actions/types";
import SlotCard from "./Slots/SlotCard";

import { FormatAMPM } from "../Utils";

const ShoppingCart = ({ match, user }) => {
  /*
  hook that makes range time between rent slot start and end rent end slot
  */
  const [timeDifference, setTimeDifference] = useState();

  // hook that makes the array of available slots
  const [slot, setSlot] = useState([]);
  if (STORAGE_PRODUCTS_CART) localStorage.removeItem(STORAGE_PRODUCTS_CART);

  /*
  hook that makes all about the pagination of the shopping cart view
  */
  // const [currentPage, setCurrentPage] = useState(1);
  // const [slotsPerPage] = useState(2);
  // const [active, setActive] = useState(1);

  /*
  hook that makes the transactions detail list viewed in costumer session (pills 'MIS RESERVACIONES')
  */
  const [pendingTransaction, setPendingTransaction] = useState([]);

  /*
    retrieves an array of id's of the parking spaces that will be added to the shopping cart for viewing
  */
  const [slotsCart, setSlotsCart] = useState([]);
  useEffect(() => {
    getSlotsCart();
  }, []);
  const getSlotsCart = () => {
    const idsSlots = localStorage.getItem(STORAGE_PRODUCTS_CART);
    if (idsSlots) {
      const idsSlotsSplit = idsSlots.split(",");
      setSlotsCart(idsSlotsSplit);
    } else {
      setSlotsCart([]);
    }
  };

  const addSlotCart = async (id, name) => {
    // add the selected slot to the shopping cart
    if (!slotsCart.includes("" + id)) {
      /* 
    send transaction info as post request to django and show transaction authentication alerts
    */
      axios.defaults.headers = {
        "Content-Type": "application/json",
      };
      const customer = match.params.user;

      const rent_start_date = match.params.startime;
      const rent_end_date = match.params.endtime;
      const parking = id;

      const body = JSON.stringify({
        parking,
        customer,
        rent_start_date,
        rent_end_date,
      });

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/parkings/transactions`,
          body
        )
        .then((res) => {
          setPendingTransaction(res.data.transaction);
          /*
          evaluates if the slot selected by the customer is already in their shopping cart
          */
          const idsSlots = slotsCart;
          idsSlots.push(id);
          setSlotsCart(idsSlots);
          localStorage.setItem(STORAGE_PRODUCTS_CART, slotsCart);
          getSlotsCart();
          toast.success(
            `Espacio de parqueo en parqueadero ${name} añadido al carrito correctamente.`
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else
      toast.info(
        `El Espacio de parqueo en parqueadero ${name} ya ha sido añadido al carrito`
      );
  };

  //generates pagination of the available slots cards
  // const indexOfLastSlot = currentPage * slotsPerPage;
  // const indexOfFirstSlot = indexOfLastSlot - slotsPerPage;
  // const currentSlots = slots.slice(indexOfFirstSlot, indexOfLastSlot);
  // const visitPage = (page) => {
  //   setCurrentPage(page);
  //   setActive(page);
  // };
  // const previous_number = () => {
  //   if (currentPage !== 1) {
  //     setCurrentPage(currentPage - 1);
  //     setActive(currentPage - 1);
  //   }
  // };
  // const next_number = () => {
  //   if (currentPage !== Math.ceil(slots.length / 2)) {
  //     setCurrentPage(currentPage + 1);
  //     setActive(currentPage + 1);
  //   }
  // };

  useEffect(() => {
    /*
      retrieves the necessary information obtained from the user
      and their selection of dates to generate the views of the available parking spaces to add to the shopping cart
    */
    const slug = match.params.slug;
    const start = match.params.startime;
    const end = match.params.endtime;
    const timeDifference = match.params.timedifference;
    setTimeDifference(timeDifference);
    const config = {
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/parkings/shoppingcart/${slug}/${start}/${end}/${timeDifference}`,
        config
      )
      .then((res) => {
        setSlot(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    match.params.slug,
    match.params.startime,
    match.params.endtime,
    match.params.timedifference,
  ]);

  return (
    <div className="x-auto">
      <Helmet>
        <title>Plataforma de Parqueo - Carrito de Compras</title>
        <meta name="description" content="shopping cart page" />
      </Helmet>
      <p className="text-muted">Bienvenido(a) {user.username}</p>
      <CartNavbar
        slotsCart={slotsCart}
        getSlotsCart={getSlotsCart}
        parkingSlot={pendingTransaction}
        rentPrice={3000}
        timeDifference={timeDifference}
      />

      <SlotCard
        parkingSlot={slot}
        startime={<FormatAMPM date={match.params.startime} />}
        endtime={<FormatAMPM date={match.params.endtime} />}
        timeDifference={timeDifference}
        price={3000}
        addSlotCart={addSlotCart}
      />

      <section>
        <ToastContainer
          position="bottom-left"
          autoClose={10000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover={false}
        />
      </section>
    </div>
  );
};

const mapStatetoProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStatetoProps, {})(ShoppingCart);
