import React, { useEffect } from "react";
import { connect } from "react-redux";
import { get_user_paid_transactions } from "../actions/transactions";

/*
library that allows configuring the description of the current 
visible page in the browser
*/
import { Helmet } from "react-helmet";

// library that allows you to build requests to the database
// import axios from "axios";

// library that customize the time format for the datepicker
import moment from "moment";
import "moment/locale/es";

//Components
import ParkingMap from "../components/ParkingMap/ParkingMap";
import { FormatAMPM } from "../components/Utils";

moment.locale("es");

const CustomerMenu = ({
  user,
  get_user_paid_transactions,
  user_paid_transactions,
}) => {
  useEffect(() => {
    if (user && user !== null && user !== undefined)
      get_user_paid_transactions(user.id, user.user_type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTransactions = (transaction) => {
    return (
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Tiquete</th>
              <th>Periodo de Alquiler</th>
              <th>Valor Pagado</th>
              <th>Devuelto</th>
            </tr>
          </thead>
          <tbody>
            {transaction.map((transactions) => (
              <tr key={transactions.ticket_id}>
                <td>{transactions.ticket_id}</td>
                <td>
                  <FormatAMPM date={transactions.rent_start_date} />-
                  <FormatAMPM date={transactions.rent_end_date} />
                </td>
                <td>${transactions.price}</td>
                <td>
                  {transactions.rent_returned.toString() === "true" ? (
                    <>Sí</>
                  ) : (
                    <>No</>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  return (
    <>
      <Helmet>
        <title>Plataforma de Parqueo - Menú de Cliente</title>
        <meta name="description" content="owner page" />
      </Helmet>
      {user && user !== null && user !== undefined ? (
        <>
          <p className="text-muted">Bienvenido(a) {user.username}</p>
          <div className="d-grid">
            <button
              className="btn btn-primary fas fa-calendar-day"
              data-bs-toggle="collapse"
              data-bs-target="#collapseReservations"
              aria-expanded="false"
              aria-controls="collapseReservations"
            >
              MIS RESERVACIONES
            </button>
          </div>
          <div id="collapseReservations" className="collapse">
            <div className="card-body">
              {user_paid_transactions &&
              user_paid_transactions !== null &&
              user_paid_transactions !== undefined ? (
                getTransactions(user_paid_transactions)
              ) : (
                <div className="card text-center">
                  <div className="card-body">
                    <p className="card-title">
                      No tienes compras realizadas en este parqueadero
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <ParkingMap user={user.id} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStatetoProps = (state) => ({
  user: state.auth.user,
  user_paid_transactions: state.transactions.transactions,
});

export default connect(mapStatetoProps, { get_user_paid_transactions })(
  CustomerMenu
);
