// library that allows you to build requests to the database
import axios from "axios";

import { GET_LOCATIONS_SUCCESS, GET_LOCATIONS_FAIL } from "./types";

export const get_locations = () => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/location/locations`,
      config
    );
    if (res.data.departments) {
      dispatch({
        type: GET_LOCATIONS_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_LOCATIONS_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_LOCATIONS_FAIL,
    });
  }
};
