import React, { useState } from "react";

import { Link, Redirect } from "react-router-dom";

/*
library that allows configuring the description of the current 
visible page in the browser
*/
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

/*
arrow function where send login form data as post request to django and show login authentication alerts
*/
import { login } from "../actions/auth";

// library that configures page loading animation
import Loader from "react-loader-spinner";

const Login = ({ login, isAuthenticated, loading, user }) => {
  //user login form
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    user_type: "Cliente",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    login(email, password);
  };

  if (isAuthenticated) {
    if (user && user !== null && user !== undefined) {
      if (user.user_type === "Administrador")
        return <Redirect to="/adminmenu" />;
      else if (user.user_type === "Propietario de Parqueadero")
        return <Redirect to="/ownermenu" />;
      else return <Redirect to="/customermenu" />;
    }
  }

  return (
    <div className="col-md-6 m-auto">
      <Helmet>
        <title>Plataforma de Parqueo - Iniciar Sesión</title>
        <meta name="description" content="login page" />
      </Helmet>
      <h1 className="text-center">Inicio de Sesión</h1>
      <div className="card card-body mt-5">
        <p className="text-center">Inicia Sesión en tu Cuenta</p>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <i className="fas fa-at">Correo Electrónico</i>
            <input
              className="form-control"
              name="email"
              onChange={(e) => onChange(e)}
              value={email}
              placeholder="ejemplo@correo.com"
              autoFocus
              required
              type="email"
            />
          </div>
          <div className="form-group">
            <i className="fas fa-key">Contraseña</i>
            <input
              className="form-control"
              minLength="3"
              name="password"
              onChange={(e) => onChange(e)}
              value={password}
              placeholder="*****"
              type="password"
            />
          </div>
          {loading ? (
            <div className="mt-3 d-flex justify-content-center align-items-center">
              <Loader type="Oval" color="#424242" height={50} width={50} />
            </div>
          ) : (
            <div className="d-grid">
              <button className="btn btn-primary">Iniciar Sesión</button>
            </div>
          )}
        </form>
        {/* <p>{JSON.stringify(formData)}</p> */}
        <p className="mt-3">
          ¿No tienes una cuenta?&nbsp;
          <Link to="/#signup">Regístrate</Link>
        </p>
        <p className="mt-3">
          ¿Olvidaste tu contraseña?&nbsp;
          <Link to="reset_password">Cambia tu contraseña</Link>
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  user: state.auth.user,
});

export default connect(mapStateToProps, { login })(Login);
