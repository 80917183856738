// library that allows you to build requests to the database
import axios from "axios";
// import { load_user } from "./profile"; // New!. Avoid that the username lost during refresh

import { setAlert } from "./alert";
import {
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_CONFIRM_SUCCESS,
  RESET_PASSWORD_CONFIRM_FAIL,
  SET_AUTH_LOADING,
  REMOVE_AUTH_LOADING,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  REFRESH_SUCCESS,
  REFRESH_FAIL,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  LOGOUT,

  // DELETE_USER_SUCCESS,
  // DELETE_USER_FAIL,
} from "./types";

export const check_authenticated = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      token: localStorage.getItem("access"),
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/jwt/verify/`,
        body,
        config
      );

      if (res.data.code !== "token_not_valid") {
        dispatch({
          type: AUTHENTICATED_SUCCESS,
        });
      } else {
        dispatch({
          type: AUTHENTICATED_FAIL,
        });
      }
    } catch (err) {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  } else {
    dispatch({
      type: AUTHENTICATED_FAIL,
    });
  }
};

export const signup =
  ({ username, email, phone, password, re_password, user_type }) =>
  async (dispatch) => {
    dispatch({
      type: SET_AUTH_LOADING,
    });
    const config = {
      headers: {
        Accept: "application/json", //NEW!.Accept return Responses from BE
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      username,
      email,
      password,
      re_password,
      user_type,
      phone,
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/users/`,
        body,
        config
      );
      if (res.data.email) {
        dispatch({
          type: SIGNUP_SUCCESS,
          payload: res.data,
        });
        dispatch(
          setAlert("Tu cuenta ha sido activada exitosamente", "success")
        );
        // dispatch(
        //   setAlert(
        //     "Te hemos enviado un email, por favor activa tu cuenta",
        //     "success"
        //   )
        // );
        dispatch(login(email, password));
      } else {
        dispatch({
          type: SIGNUP_FAIL,
        });
        dispatch(setAlert("Error al crear la cuenta", "danger"));
      }
      dispatch({
        type: REMOVE_AUTH_LOADING,
      });
    } catch (err) {
      dispatch({
        type: SIGNUP_FAIL,
      });
      dispatch({
        type: REMOVE_AUTH_LOADING,
      });
      if (err.response.data.password)
        dispatch(setAlert(err.response.data.password, "danger"));
      if (err.response.data.username)
        dispatch(setAlert(err.response.data.username, "danger"));
      if (err.response.data.email)
        dispatch(setAlert(err.response.data.email, "danger"));
      if (err.response.data.phone)
        dispatch(setAlert(err.response.data.phone, "danger"));
      dispatch(setAlert("Error al crear la cuenta", "danger"));
    }
  };

export const load_user = () => async (dispatch) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json", //NEW!.Accept return Responses from BE
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/users/me/`,
        config
      );
      if (res.data.email) {
        dispatch({
          type: USER_LOADED_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: USER_LOADED_FAIL,
        });
      }
    } catch (err) {
      dispatch({
        type: USER_LOADED_FAIL,
      });
    }
  }
};

export const login = (email, password) => async (dispatch) => {
  dispatch({
    type: SET_AUTH_LOADING,
  });

  /*
      send login form data as post request to django and show login authentication alerts
      */
  const config = {
    headers: {
      Accept: "application/json", //NEW!.Accept return Responses from BE
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    email,
    password,
  });

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/jwt/token/`,
      body,
      config
    );
    if (res.data.access) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      dispatch(load_user());
      dispatch({
        type: REMOVE_AUTH_LOADING,
      });
      dispatch(setAlert("Inicio de sesión exitoso", "success"));
    } else {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/badlogin/`,
        body,
        config
      );
      dispatch({
        type: LOGIN_FAIL,
      });
      dispatch({
        type: REMOVE_AUTH_LOADING,
      });
      dispatch(setAlert("Error al autenticar", "danger"));
    }
  } catch (err) {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/badlogin/`,
      body,
      config
    );
    dispatch({
      type: LOGIN_FAIL,
    });
    dispatch({
      type: REMOVE_AUTH_LOADING,
    });
    dispatch(setAlert(err.response.data.detail, "danger"));
    dispatch(setAlert("Error al autenticar", "danger"));
  }
};

export const activate = (uid, token) => async (dispatch) => {
  dispatch({
    type: SET_AUTH_LOADING,
  });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    uid,
    token,
  });

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/users/activation/`,
      body,
      config
    );
    if (res.data.uid || res.data.token) {
      dispatch({
        type: ACTIVATION_FAIL,
      });
      dispatch(setAlert("Error activando la cuenta", "danger"));
    } else {
      dispatch({
        type: ACTIVATION_SUCCESS,
      });
      dispatch(setAlert("Tu cuenta ha sido activada exitosamente", "success"));
    }
    dispatch({
      type: REMOVE_AUTH_LOADING,
    });
  } catch (err) {
    dispatch({
      type: ACTIVATION_FAIL,
    });
    dispatch({
      type: REMOVE_AUTH_LOADING,
    });
    dispatch(setAlert("Error activando la cuenta", "danger"));
  }
};

export const refresh = () => async (dispatch) => {
  if (localStorage.getItem("refresh")) {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      refresh: localStorage.getItem("refresh"),
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/jwt/refresh/`,
        body,
        config
      );

      if (res.data.access) {
        dispatch({
          type: REFRESH_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: REFRESH_FAIL,
        });
      }
    } catch (err) {}
  } else {
    dispatch({
      type: REFRESH_FAIL,
    });
  }
};

export const reset_password = (email) => async (dispatch) => {
  dispatch({
    type: SET_AUTH_LOADING,
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ email });

  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/users/reset_password/`,
      body,
      config
    );

    dispatch({
      type: RESET_PASSWORD_SUCCESS,
    });
    dispatch({
      type: REMOVE_AUTH_LOADING,
    });
    dispatch(
      setAlert(
        "Te hemos enviado un email para poder cambiar tu contraseña",
        "success"
      )
    );
  } catch {
    dispatch({
      type: RESET_PASSWORD_FAIL,
    });
    dispatch({
      type: REMOVE_AUTH_LOADING,
    });
    dispatch(
      setAlert(
        "Error al enviar el email para restablecimiento de la contraseña",
        "danger"
      )
    );
  }
};

export const reset_password_confirm =
  (uid, token, new_password, re_new_password) => async (dispatch) => {
    dispatch({
      type: SET_AUTH_LOADING,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      uid,
      token,
      new_password,
      re_new_password,
    });

    if (new_password !== re_new_password) {
      dispatch({
        type: RESET_PASSWORD_CONFIRM_FAIL,
      });
      dispatch(setAlert("Las contraseñas no coinciden", "danger"));
    } else {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`,
          body,
          config
        );

        dispatch({
          type: RESET_PASSWORD_CONFIRM_SUCCESS,
        });
        dispatch({
          type: REMOVE_AUTH_LOADING,
        });
        dispatch(
          setAlert("¡Tu contraseña ha sido cambiada exitosamente!", "success")
        );
      } catch {
        dispatch({
          type: RESET_PASSWORD_CONFIRM_FAIL,
        });
        dispatch({
          type: REMOVE_AUTH_LOADING,
        });
        dispatch(setAlert("Error al cambiar la contraseña", "danger"));
      }
    }
  };

export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
  dispatch(setAlert("Cerrada la sesión", "success"));
};

// export const delete_account = () => async (dispatch) => {
//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//     },
//   };

//   const body = JSON.stringify({ withCredentials: true });

//   try {
//     const res = await axios.delete(
//       `${process.env.REACT_APP_API_URL}/users/delete`,
//       config,
//       body
//     );
//     if (res.data.exito) {
//       dispatch({
//         type: DELETE_USER_SUCCESS,
//       });
//     } else {
//       dispatch({
//         type: DELETE_USER_FAIL,
//       });
//     }
//   } catch (err) {
//     dispatch({
//       type: DELETE_USER_FAIL,
//     });
//   }
// };
