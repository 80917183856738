import React, { useState } from "react";

/*
library that allows configuring the description of the current 
visible page in the browser
*/
import { Helmet } from "react-helmet";

//Components
import backgroundlandingpage from "../../assets/background-landingpage.jpg";
import ParkingMap from "../../components/ParkingMap/ParkingMap";
import SignUp from "../../components/SignUp";

// home styles
import "./_home.scss";

const Home = () => {
  const [site, setSite] = useState([]);

  return (
    <>
      <Helmet>
        <title>Plataforma de Parqueo - Inicio</title>
        <meta
          name="description"
          content="Plataforma de Parqueo Página de Inicio"
        />
      </Helmet>
      <div id="header" className="container position-relative style">
        <img
          src={backgroundlandingpage}
          className="img-fluid rounded mx-auto d-block"
          alt="imagen-cabecera"
        />

        <div>
          <h1>
            Reserva con nosotros,
            <br />
            paga tu tiempo de estacionamiento en el parqueadero.
          </h1>
          <h3>
            <em>
              Te garantizamos que un espacio libre para tu vehículo estará
              esperándote
            </em>
          </h3>
        </div>
      </div>
      <div id="parkingmap" className="text-center">
        <i>
          Reserva tu espacio de parqueadero, sin dar vueltas, sin estres ni
          complicaciones
        </i>
        <ParkingMap setSite={setSite} site={site} />
      </div>

      <div id="signup">
        <SignUp />
      </div>
    </>
  );
};

export default Home;
