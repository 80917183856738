// library that allows you to build requests to the database
import axios from "axios";

import {
  GET_USER_TRANSACTIONS_SUCCESS,
  GET_USER_TRANSACTIONS_FAIL,
  GET_OWNER_DAILY_TRANSACTIONS_SUCCESS,
  GET_OWNER_DAILY_TRANSACTIONS_FAIL,
} from "./types";

export const get_user_paid_transactions =
  (user_id, user_type) => async (dispatch) => {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ user_id, user_type });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/parkings/usertransactions`,
        body,
        config
      );
      if (!res.data.error) {
        dispatch({
          type: GET_USER_TRANSACTIONS_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_USER_TRANSACTIONS_FAIL,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_USER_TRANSACTIONS_FAIL,
      });
    }
  };

axios.defaults.headers = {
  "Content-Type": "application/json",
};

export const get_owner_daily_paid_transactions =
  (user_id, startday, endday) => async (dispatch) => {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({ user_id, startday, endday });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/parkings/ownerdailytransactions`,
        body,
        config
      );
      if (!res.data.error) {
        dispatch({
          type: GET_OWNER_DAILY_TRANSACTIONS_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_OWNER_DAILY_TRANSACTIONS_FAIL,
        });
      }
    } catch (err) {
      dispatch({
        type: GET_OWNER_DAILY_TRANSACTIONS_FAIL,
      });
    }
  };
