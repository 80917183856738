import React, { useState } from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { reset_password_confirm } from "../actions/auth";

// library that configures page loading animation
import Loader from "react-loader-spinner";

const ResetPasswordConfirm = ({ match, loading, reset_password_confirm }) => {
  const [requestSent, setRequestSent] = useState(false);

  const [formData, setFormData] = useState({
    new_password: "",
    re_new_password: "",
  });

  const { new_password, re_new_password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    const uid = match.params.uid;
    const token = match.params.token;

    reset_password_confirm(uid, token, new_password, re_new_password);
    if (new_password === re_new_password) setRequestSent(true);
  };

  if (requestSent && loading) return <Redirect to="/login" />;

  return (
    <div className="container mt-5">
      <h1 className="mb-5">Cambia tu contraseña:</h1>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <i className="fas fa-lock-open">Nueva Contraseña (*)</i>
          <input
            className="form-control"
            name="new_password"
            onChange={(e) => onChange(e)}
            placeholder="*****"
            type="password"
          />
        </div>
        <div className="form-group">
          <i className="fas fa-lock">Confirmar Nueva Contraseña</i>
          <input
            className="form-control"
            name="re_new_password"
            onChange={(e) => onChange(e)}
            placeholder="*****"
            type="password"
          />
        </div>
        {loading ? (
          <div className="mt-3 d-flex justify-content-center align-items-center flex-column">
            <Loader type="Oval" color="#424242" height={50} width={50} />
          </div>
        ) : (
          <button className="btn btn-primary" type="submit">
            Cambiar contraseña
          </button>
        )}
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});

export default connect(mapStateToProps, { reset_password_confirm })(
  ResetPasswordConfirm
);
