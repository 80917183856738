import React, { useEffect } from "react";
import { connect } from "react-redux";
import { get_user_paid_transactions } from "../actions/transactions";
import { get_owner_daily_paid_transactions } from "../actions/transactions";

/*
library that allows configuring the description of the current 
visible page in the browser
*/
import { Helmet } from "react-helmet";

// library that customize the time format for the datepicker
import moment from "moment";
import "moment/locale/es";

import { FormatAMPM } from "../components/Utils";

moment.locale("es");

const OwnerMenu = ({
  get_user_paid_transactions,
  user_paid_transactions,
  get_owner_daily_paid_transactions,
  owner_daily_paid_transactions,
  user,
}) => {
  useEffect(() => {
    if (user && user !== null && user !== undefined) {
      get_user_paid_transactions(user.id, user.user_type);
      get_owner_daily_paid_transactions(
        user.id,
        moment().startOf("day").format(),
        moment().endOf("day").format()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTransactions = (transaction) => {
    return (
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Tiquete</th>
              <th>Periodo de Alquiler</th>
              <th>Valor a Pagar</th>
              <th>Devuelto</th>
            </tr>
          </thead>
          <tbody>
            {transaction.map((transactions) => (
              <tr key={transactions.ticket_id}>
                <td>{transactions.ticket_id}</td>
                <td>
                  <FormatAMPM date={transactions.rent_start_date} />-
                  <FormatAMPM date={transactions.rent_end_date} />
                </td>
                <td>${transactions.price}</td>
                <td>
                  {transaction.rent_returned !== false ? <>No</> : <>Sí</>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  return (
    <div className="col-md-6 m-auto">
      <Helmet>
        <title>
          Plataforma de Parqueo - Menú de Propietario del Parqueadero
        </title>
        <meta name="description" content="owner page" />
      </Helmet>
      {user && user !== null && user !== undefined ? (
        <p className="text-muted">Bienvenido(a) propietario {user.username}</p>
      ) : (
        <></>
      )}
      <div id="accordion">
        <div className="card">
          <div id="ownerOptionOne">
            <div className="d-grid">
              <button
                className="btn btn-outline-info btn-lg btn-block"
                data-bs-toggle="collapse"
                data-bs-target="#collapsePerfile"
                aria-expanded="true"
                aria-controls="collapsePerfile"
              >
                <i className="fas fa-star"></i>
                Perfil
              </button>
            </div>
          </div>
          <div
            id="collapsePerfile"
            className="collapse"
            aria-labelledby="ownerOptionOne"
            data-parent="#accordion"
          >
            <div className="card-body">
              <h3>Perfil</h3>
            </div>
          </div>
        </div>

        <div className="card">
          <div id="ownerOptionTwo">
            <div className="d-grid">
              <button
                className="btn btn-outline-info btn-lg btn-block"
                data-bs-toggle="collapse"
                data-bs-target="#collapseDayReservations"
                aria-expanded="true"
                aria-controls="collapseDayReservations"
              >
                <i className="fas fa-calendar-day"></i>
                RESERVACIONES DEL DIA
              </button>
            </div>
          </div>
          <div
            id="collapseDayReservations"
            className="collapse show"
            aria-labelledby="ownerOptionTwo"
            data-parent="#accordion"
          >
            <div className="card-body">
              <h3>Reservaciones del Día</h3>
              {owner_daily_paid_transactions &&
              owner_daily_paid_transactions !== null &&
              owner_daily_paid_transactions !== undefined ? (
                getTransactions(owner_daily_paid_transactions)
              ) : (
                <div className="card text-center">
                  <div className="card-body">
                    <p className="card-title">No hay reservaciones</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="card">
          <div id="ownerOptionThree">
            <div className="d-grid">
              <button
                className="btn btn-outline-info btn-lg btn-block"
                data-bs-toggle="collapse"
                data-bs-target="#collapseAvailableSlotsUpdate"
                aria-expanded="true"
                aria-controls="collapseAvailableSlotsUpdate"
              >
                <i className="fas fa-edit"></i>
                Actualizar Espacios de Alquiler
              </button>
            </div>
          </div>
          <div
            id="collapseAvailableSlotsUpdate"
            className="collapse"
            aria-labelledby="ownerOptionThree"
            data-parent="#accordion"
          >
            <div className="card-body">
              <h3>Actualizar Espacios de Alquiler</h3>
            </div>
          </div>
        </div>

        <div className="card">
          <div id="ownerOptionFour">
            <div className="d-grid">
              <button
                className="btn btn-outline-info btn-lg btn-block"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTransactionsList"
                aria-expanded="true"
                aria-controls="collapseTransactionsList"
              >
                <i className="fas fa-list-alt"></i>
                Lista de Transacciones
              </button>
            </div>
          </div>
          <div
            id="collapseTransactionsList"
            className="collapse"
            aria-labelledby="ownerOptionFour"
            data-parent="#accordion"
          >
            <div className="card-body">
              <h3>Lista de Transacciones</h3>
              {user_paid_transactions &&
              user_paid_transactions !== null &&
              user_paid_transactions !== undefined ? (
                getTransactions(user_paid_transactions)
              ) : (
                <div className="card text-center">
                  <div className="card-body">
                    <p className="card-title">No hay transacciones</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStatetoProps = (state) => ({
  user: state.auth.user,
  user_paid_transactions: state.transactions.transactions,
  owner_daily_paid_transactions: state.transactions.dailytransactions,
});

export default connect(mapStatetoProps, {
  get_user_paid_transactions,
  get_owner_daily_paid_transactions,
})(OwnerMenu);
