import React from "react";

/*
library that allows configuring the description of the current 
visible page in the browser
*/
import { Helmet } from "react-helmet";

const Pqrs = () => {
  return (
    <>
      <Helmet>
        <title>Plataforma de Parqueo - Preguntas Frecuentes</title>
        <meta name="description" content="pqrs" />
      </Helmet>
      <div className="x-auto"></div>
    </>
  );
};

export default Pqrs;
