import React from "react";

/*
library that allows configuring the description of the current 
visible page in the browser
*/
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Plataforma de Parqueo - Contacto</title>
        <meta name="description" content="contact us" />
      </Helmet>
      <h1 className="text-center">Estamos aquí para ayudarte</h1>
      <div className="x-auto">
        <div className="card card-body mt-5 text-center">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <h4>
                  <i className="fas fa-phone">Llámanos</i>
                </h4>
                <h3>
                  <a href="tel:+573194986813">(+57) 319 498 6813</a>
                </h3>
              </div>
              <div className="col-sm-6">
                <h4>
                  <i className="fas fa-envelope">Escríbenos</i>
                </h4>
                <h3>
                  <a href="mailto:help@ritechgroup.com">help@ritechgroup.com</a>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
