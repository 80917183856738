import { GET_LOCATIONS_SUCCESS, GET_LOCATIONS_FAIL } from "../actions/types";

const initialState = {
  locations: null,
};

export default function locations(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: payload.departments,
      };
    case GET_LOCATIONS_FAIL:
      return {
        ...state,
        locations: null,
      };
    default:
      return state;
  }
}
