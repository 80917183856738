import React from "react";
import { Link } from "react-router-dom";
import { Marker, Popup } from "react-leaflet";

export const GeoMarker = ({ geo, icon }) => {
  // Geolocation popup
  const geomarker = (
    <Marker position={geo.currentLocation} icon={icon}>
      <Popup>
        <>
          Tu estás aquí
          <p>
            Coordenadas: [{geo.currentLocation.lat},{geo.currentLocation.lng}]
          </p>
        </>
      </Popup>
    </Marker>
  );
  return geomarker;
};

export const ParkingMarkers = ({
  parkings,
  icon,
  startime,
  endtime,
  timedifference,
  user,
}) => {
  console.log(parkings);
  // Parking popup
  const parkingmarkers = parkings.map((parking) => (
    <Marker
      position={[
        parking.schedule_prkg.prkg_latitude,
        parking.schedule_prkg.prkg_longitude,
      ]}
      icon={icon}
      key={parking.id}
    >
      <Popup>
        <div className="row">
          <h3>Parqueadero {parking.schedule_prkg.prkg_name}</h3>
          <div className="col-6">
            <img
              src={process.env.REACT_APP_API_URL + "/media/" + parking.photo}
              className="img-fluid"
              alt="Parking"
            />
          </div>
          <div className="col-6">
            <p>
              <b>Dirección:</b>
              <br />
              {parking.schedule_prkg.prkg_address},
              {parking.schedule_prkg.prkg_zone.name}
            </p>
            {parking.state === "ABIERTO" ? (
              <p>
                <b>{parking.state}</b>
                <br />
                Hasta
                {parking.end_attention.split(":")[0] * 1 < 12
                  ? " las " +
                    parking.end_attention.split(":")[0] * 1 +
                    ":" +
                    parking.end_attention.split(":")[1] +
                    " de la Madrugada"
                  : (parking.end_attention.split(":")[0] * 1 === 23) &
                    (parking.end_attention.split(":")[1] * 1 === 59) &
                    (parking.end_attention.split(":")[2] * 1 === 59)
                  ? " la Medianoche"
                  : " las " +
                    (parking.end_attention.split(":")[0] * 1 - 12) +
                    ":" +
                    parking.end_attention.split(":")[1] +
                    " PM"}
              </p>
            ) : (
              <p>
                <b>{parking.state}</b>
                <br />
                <b>Horario de Atención {parking.day_type}:</b>
                <br />
                De{" "}
                {parking.start_attention.split(":")[0] * 1 < 12
                  ? parking.start_attention.split(":")[0] * 1 +
                    ":" +
                    parking.start_attention.split(":")[1] +
                    " AM"
                  : parking.start_attention.split(":")[0] * 1 -
                    12 +
                    ":" +
                    parking.start_attention.split(":")[1] +
                    " PM"}{" "}
                a{" "}
                {parking.end_attention.split(":")[0] * 1 < 12
                  ? parking.end_attention.split(":")[0] * 1 +
                    ":" +
                    parking.end_attention.split(":")[1] +
                    " AM"
                  : (parking.end_attention.split(":")[0] * 1 === 23) &
                    (parking.end_attention.split(":")[1] * 1 === 59) &
                    (parking.end_attention.split(":")[2] * 1 === 59)
                  ? "Medianoche"
                  : parking.end_attention.split(":")[0] * 1 -
                    12 +
                    ":" +
                    parking.end_attention.split(":")[1] +
                    " PM"}
              </p>
            )}
            {parking.slots !== 0 ? (
              parking.state === "ABIERTO" ? (
                <p>
                  <b>Espacios Disponibles:</b>
                  <br />
                  <Link
                    to={`shoppingcart/${parking.schedule_prkg.id}/${startime}/${endtime}/${timedifference}/${user}`}
                  >
                    {parking.slots}
                  </Link>{" "}
                  de {parking.prkg_slots}
                </p>
              ) : (
                <></>
              )
            ) : (
              <p>
                <b>Espacios Disponibles:</b>
                <br />
                {parking.slots} de {parking.prkg_slots}
              </p>
            )}
            <details>
              <summary>
                <b>Más info:</b>
              </summary>
              <>{parking.schedule_prkg.prkg_more_info}</>
            </details>
          </div>
        </div>
      </Popup>
    </Marker>
  ));
  return parkingmarkers;
};

export const SiteMarkers = ({ location, icon }) => {
  // Department popup
  const sitemarkers = (
    <Marker
      position={[location.latitude, location.longitude]}
      icon={icon}
      key={location.id}
    >
      <Popup>
        <div className="row">
          <p>Departamento de {location.name}</p>
          <p>
            Coordenadas: [{location.latitude},{location.longitude}]
          </p>
        </div>
      </Popup>
    </Marker>
  );
  return sitemarkers;
};
