import React, { useState, useEffect } from "react";

// shopping cart icon buttons
import { ReactComponent as CartEmpty } from "../../../../assets/cart-empty.svg";
import { ReactComponent as CartFull } from "../../../../assets/cart-full.svg";
import { ReactComponent as Close } from "../../../../assets/close.svg";
import { ReactComponent as Garbage } from "../../../../assets/garbage.svg";

import { STORAGE_PRODUCTS_CART } from "../../../../actions/types";
import { removeArrayDuplicates } from "../../../../actions/arrayFunc";

// library that allows you to build requests to the database
import axios from "axios";

// library that allows to manipulate external widgets
import ScriptTag from "react-script-tag";

// shopping cart styles
import "./_cart.scss";

export const Cart = (props) => {
  const { slotsCart, getSlotsCart, parkingSlot, rentPrice, timeDifference } =
    props;

  /* hook that controls the animated display of transaction shopping cart. Hidden default (false)
   */
  const [cartOpen, setCartOpen] = useState(false);

  /* controls the width of the animated shopping cart transaction menu depending on the cartOpen state
   */
  const widthCartContent = cartOpen ? 300 : 0;

  /* hook that generate the array of slots selected for the    shopping cart transaction
   */
  const [singleSlotsCart, setSingleSlotsCart] = useState([]);

  /*
  hook that adjusts the total value of the purchase to be displayed in the animated shopping cart transaction menu
  */
  const [cartTotalPrice, setCartTotalPrice] = useState(0);

  useEffect(() => {
    const allSlotsId = removeArrayDuplicates(slotsCart);
    setSingleSlotsCart(allSlotsId);
  }, [slotsCart]);

  useEffect(() => {
    /* hook that adds the information of the slots selected by the user in the animated menu of the shopping cart for their transaction
     */
    const slotData = [];
    let totalPrice = 0;
    const allSlotsId = removeArrayDuplicates(slotsCart);
    allSlotsId.forEach((slotId) => {
      const slotValue = {
        id: slotId,
      };
      slotData.push(slotValue);
      totalPrice = totalPrice + rentPrice;
    });

    setCartTotalPrice(totalPrice);
  }, [slotsCart, parkingSlot, timeDifference, rentPrice]);

  const openCart = () => {
    /* these arrow function control the onClick cart button and opening of the animated transaction menu
     */
    setCartOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeCart = () => {
    /* these arrow function control the onClick "X" button closing of the animated    transaction menu
     */
    setCartOpen(false);
    document.body.style.overflow = "scroll";
  };

  const deleteTransaction = async (ticket_id) => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
    };
    const body = JSON.stringify({
      ticket_id,
    });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/parkings/deletetransaction`,
        body
      )
      .then((res) => {
        getSlotsCart();
      })
      .catch((err) => console.log(err));
  };

  const emptyCart = () => {
    /* these arrow function control the onClick trash button of the animated shopping cart transaction menu
     */
    localStorage.removeItem(STORAGE_PRODUCTS_CART);
    deleteTransaction(parkingSlot.ticket_id);
    getSlotsCart();
  };

  return (
    <>
      <button className="cart btn btn-secondary">
        {/* animation of icon shopping cart full or empty */}
        {slotsCart.length > 0 ? (
          <CartFull onClick={openCart} />
        ) : (
          <CartEmpty onClick={openCart} />
        )}
      </button>

      {/* style={{ width: widthCartContent }} control the opening and closing animation of shopping cart transaction menu */}
      <div className="cart-content" style={{ width: widthCartContent }}>
        <CartContentHeader closeCart={closeCart} emptyCart={emptyCart} />
        <div className="cart-content__slots">
          {singleSlotsCart.map((idSlotCart, index) => (
            <CartContentSlots
              key={index}
              parkingSlot={parkingSlot}
              rentPrice={rentPrice}
              idSlotCart={idSlotCart}
              maxtime={timeDifference}
            />
          ))}
        </div>
        <CartContentFooter
          parking={parkingSlot}
          cartTotalPrice={cartTotalPrice}
        />
      </div>
    </>
  );
};

const CartContentHeader = (props) => {
  /*
  Render the header of shopping cart transaction menu (X, Carrito, Vaciar)
  */
  const { closeCart, emptyCart } = props;
  return (
    <div className="cart-content__header">
      <div>
        <Close onClick={closeCart} />
        <h2>Carrito</h2>
      </div>
      <button className="cart btn btn-secondary" onClick={emptyCart}>
        Vaciar
        <Garbage />
      </button>
    </div>
  );
};

const CartContentSlots = (props) => {
  /*
  Define the content of shopping cart transaction menu (Slot photo, Espacio, Precio de Alquiler, '-' button)
  */
  const { parkingSlot, rentPrice, maxtime } = props;
  if (parkingSlot.length !== 0) {
    return (
      <RenderSlot
        key={parkingSlot.id}
        slot={parkingSlot}
        maxtime={maxtime}
        rentPrice={rentPrice}
      />
    );
  }

  return null;
};

const RenderSlot = (props) => {
  /* 
  Render the content of shopping cart transaction menu (Slot photo, Espacio, Precio de Alquiler, '-' button)
  */
  const { slot, maxtime, rentPrice } = props;
  return (
    <div className="cart-content__slot">
      <div className="cart-content__slot-info">
        <div>
          <h3>
            Espacio en: <p>Parqueadero {slot.parking.prkg_name}</p>
          </h3>
          {slot.parking.prkg_address}
          <p>{maxtime} minutos</p>
          <p>Precio de alquiler: ${rentPrice}</p>
        </div>
        <div></div>
      </div>
    </div>
  );
};

const CartContentFooter = (props) => {
  /* 
  The CartContentFooter component render the content of shopping cart transaction menu ("Total", "Realizar Pedido")
  */
  const { parking, cartTotalPrice } = props;
  if (cartTotalPrice === 3000) {
    return (
      <>
        <div className="cart-content__footer">
          <div>
            <p>Total: </p>
            <p>${cartTotalPrice}</p>
          </div>
          <div>
            <ScriptTag
              src="https://checkout.wompi.co/widget.js"
              data-render="button"
              data-public-key={process.env.REACT_APP_WOMPI_PUBLIC_KEY_PROD}
              data-currency="COP"
              data-amount-in-cents={parking.price + "00"}
              data-reference={parking.ticket_id}
              data-redirect-url={
                process.env.REACT_APP_WOMPI_REDIRECT_URL + parking.id + "/check"
              }
            />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="cart-content__footer">
          <div>
            <p>Total: </p>
            <p>${cartTotalPrice}</p>
          </div>
        </div>
      </>
    );
  }
};

export default Cart;
