import React from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";

import { logout } from "../actions/auth";

const Navbar = ({ isAuthenticated, logout, user }) => {
  const authLinks = (
    /*
    navigation bar with identified user
    */
    <>
      {user && user !== null && user !== undefined ? (
        user.user_type === "Administrador" ? (
          <a className="nav-link" href="/adminmenu">
            <i className="fa-solid fa-grid-horizontal"></i>Menú del
            administrador(a)
            {user.username}
          </a>
        ) : user.user_type === "Propietario de Parqueadero" ? (
          <a className="nav-link" href="/ownermenu">
            <i className="fa-solid fa-grid-horizontal"></i>Menú del
            propietario(a) {user.username}
          </a>
        ) : (
          <a className="nav-link" href="/customermenu">
            <i className="fa-solid fa-grid-horizontal"></i>Menú de{" "}
            {user.username}
          </a>
        )
      ) : (
        <></>
      )}

      <a className="nav-link" onClick={logout} href="/login">
        <i className="fa-solid fa-right-from-bracket"></i>Cerrar sesión
      </a>
      <a className="nav-link" href="/contact">
        <i className="fas fa-inbox"></i>Contáctanos
      </a>
      <a className="nav-link" href="/pqrs">
        <i className="fas fa-question"></i>Preguntas Frecuentes
      </a>
    </>
  );

  const guestLinks = (
    /*
    navigation bar with anonymous user
    */
    <>
      <a className="nav-link" href="/login">
        <i className="fas fa-sign-in-alt"></i>Iniciar Sesión
      </a>
      <a className="nav-link" href="/#parkingmap">
        <i className="fas fa-map-marked-alt"></i>Ir al mapa
      </a>
      <a className="nav-link" href="/#signup">
        <i className="fas fa-user-plus"></i>Regístrate
      </a>
      <a className="nav-link" href="/contact">
        <i className="fas fa-inbox"></i>Contáctanos
      </a>
      <a className="nav-link" href="/pqrs">
        <i className="fas fa-question"></i>Preguntas Frecuentes
      </a>
    </>
  );

  return (
    <nav
      id="navbar"
      className="fixed-top navbar navbar-expand-sm navbar-light bg-light"
    >
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarToggler"
          aria-controls="navbarToggler"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <Link className="navbar-brand" to="/">
          YoParqueo
        </Link>
        <div className="collapse navbar-collapse" id="navbarToggler">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <NavLink className="nav-link" exact to="/">
              <i className="fas fa-home"></i>Inicio
            </NavLink>
            {isAuthenticated ? authLinks : guestLinks}
          </ul>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(Navbar);
