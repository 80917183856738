import {
  GET_USER_TRANSACTIONS_SUCCESS,
  GET_USER_TRANSACTIONS_FAIL,
  GET_OWNER_DAILY_TRANSACTIONS_SUCCESS,
  GET_OWNER_DAILY_TRANSACTIONS_FAIL,
} from "../actions/types";

const initialState = {
  transactions: null,
  dailytransactions: null,
};

export default function parkings(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_USER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: payload,
      };
    case GET_OWNER_DAILY_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        dailytransactions: payload,
      };
    case GET_USER_TRANSACTIONS_FAIL:
      return {
        ...state,
        transactions: null,
      };
    case GET_OWNER_DAILY_TRANSACTIONS_FAIL:
      return {
        ...state,
        dailytransactions: null,
      };
    default:
      return state;
  }
}
