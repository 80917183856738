import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import locations from "./locations";
import parkings from "./parkings";
import transactions from "./transactions";
// import profile from "./profile";

export default combineReducers({
  auth,
  alert,
  locations,
  parkings,
  transactions,
  // profile,
});
