import React, { Fragment } from "react";
import { FormatAMPM } from "./Utils";

const TransactionDescription = ({ transactionDescription }) => {
  let resume = transactionDescription.map((resume) => (
    <Fragment key={resume.ticket_id}>
      <h4>
        <strong>Estos son los detalles de tu reserva:</strong>
      </h4>
      <div>
        <b>Tiquete de alquiler: </b>
        {resume.ticket_id}
      </div>
      <div>
        <b>Parqueadero: </b>
        {resume.parking.prkg_name}
      </div>
      <div>
        <b>Inicio de la reserva:</b>{" "}
        <FormatAMPM date={resume.rent_start_date} />
      </div>
      <div>
        <b>Fin de la reserva:</b> <FormatAMPM date={resume.rent_end_date} />
      </div>
    </Fragment>
  ));
  return resume;
};

export default TransactionDescription;
