export const FormatAMPM = ({ date }) => {
  date = date.split(/\D+/);
  /*
    returns the rental time range in Spanish format. visible in the transaction lists found on the pills of customer session
    */
  let day = date[2];
  let month = parseInt(date[1]);
  let hours = parseInt(date[3]);
  let minutes = date[4];
  let ampm = hours >= 12 ? "PM" : "AM";
  month =
    month === 1
      ? "ene."
      : month === 2
      ? "feb."
      : month === 3
      ? "mar."
      : month === 4
      ? "abr."
      : month === 5
      ? "may."
      : month === 6
      ? "jun."
      : month === 7
      ? "jul."
      : month === 8
      ? "ago."
      : month === 9
      ? "sep."
      : month === 10
      ? "oct."
      : month === 11
      ? "nov."
      : "dic.";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  let strTime = month + " " + day + " " + hours + ":" + minutes + " " + ampm;
  return strTime;
};
