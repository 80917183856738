export const removeArrayDuplicates = (array) => {
  /*
    function that fixes that a slot cannot be added to the shopping cart more than once.
    for example if array=[1,2,2]
    then Array.from(new Set(array)) equals to [1,2]
  */
  return Array.from(new Set(array));
};

export const removeItemArray = (array, item) => {
  /*
  function called by the '-' button of a slot in the shopping cart that allows it to be discarded.
  for example if array=['3','6','7','8','9'] and item='8'
  then index equals to 3 (item position)
  array.splice(index,1) equals to ['3','6','7','9']
  */
  const index = array.indexOf(item);
  if (index > -1) {
    array.splice(index, 1);
  }
  return array;
};
