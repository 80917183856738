import React from "react";
import { Map, TileLayer } from "react-leaflet";

// library that imports location marker icons on the map
import { UserLocation, ParkingLocation, SiteLocation } from "./MapView/Icons";

// library that imports popup markers on the map
import { GeoMarker, ParkingMarkers, SiteMarkers } from "./MapView/Markers";

export const GeoMap = ({
  state,
  parkings,
  location,
  startime,
  endtime,
  timedifference,
  user,
}) => {
  /*
  date function where the views to render on the map are evaluated according to whether the user has used the search function by department / city or not
  */
  // let obj = site;
  let geomap =
    parkings && parkings !== null && parkings !== undefined ? (
      location.name === "" ? (
        //search function by department/city not used (predeterminated views: Pasto city as the center of the map with geolocation activated or Colombia country as the center of the map with geolocation deactivated)
        <Map
          center={state.currentLocation}
          zoom={state.zoom}
          className="leaflet-container"
        >
          <TileLayer url="https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/reduced.night/{z}/{x}/{y}/512/png8?apiKey=owZYQ3W2tQ9Zklc4iXjl2RHKSiIyk3HFROX2oI_3ooY&ppi=320" />
          <GeoMarker geo={state} icon={UserLocation} />
          <ParkingMarkers
            parkings={parkings}
            icon={ParkingLocation}
            startime={startime}
            endtime={endtime}
            timedifference={timedifference}
            user={user}
          />
        </Map>
      ) : (
        //   site.map((department) => (
        //search function by department/city used
        <Map
          center={[location.latitude, location.longitude]}
          zoom={location.zoom}
          className="leaflet-container"
          key={location.id}
        >
          <TileLayer url="https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/reduced.night/{z}/{x}/{y}/512/png8?apiKey=owZYQ3W2tQ9Zklc4iXjl2RHKSiIyk3HFROX2oI_3ooY&ppi=320" />
          <GeoMarker geo={state} icon={UserLocation} />
          <ParkingMarkers
            parkings={parkings}
            icon={ParkingLocation}
            startime={startime}
            endtime={endtime}
            timedifference={timedifference}
            user={user}
          />
          <SiteMarkers location={location} icon={SiteLocation} />
        </Map>
      )
    ) : (
      <></>
    );
  return geomap;
};

export default GeoMap;
