import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { get_parkings } from "../../../actions/parkings";

// library that customize the time format for the datepicker
import moment from "moment";
import "moment/locale/es";

// libraries that allows you to build the view map
import "leaflet/dist/leaflet.css";
import GeoMap from "../GeoMap";
import "./mapview.css";

/* 
library that allows you to build the datepicker to control the parking search by date range
*/
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

moment.locale("es");

export const MapView = ({ location, parkings, get_parkings, user }) => {
  /*
  hook that controls setup data for map display
  */
  const [state, setState] = useState({
    currentLocation: { lat: location.latitude, lng: location.longitude },
    zoom: location.zoom,
    style: "reduced.night",
  });

  const dateFormat = (time) => {
    /*
    rounds the specified time in minutes to multiples of 15. Serves as additional validation in the datetimepicker
    */
    if (time.minute() < 15)
      time =
        time.second() || time.millisecond()
          ? time.add(15 - time.minute(), "minute").startOf("minute")
          : time.startOf("minute");
    else if (time.minute() < 30)
      time =
        time.second() || time.millisecond()
          ? time.add(30 - time.minute(), "minute").startOf("minute")
          : time.startOf("minute");
    else if (time.minute() < 45)
      time =
        time.second() || time.millisecond()
          ? time.add(45 - time.minute(), "minute").startOf("minute")
          : time.startOf("minute");
    else if (time.minute() < 60)
      time =
        time.second() || time.millisecond()
          ? time.add(60 - time.minute(), "minute").startOf("minute")
          : time.startOf("minute");
    return time;
  };

  /* 
  hooks that adjust the date range of the search function by datepicker
  */
  const [startime, setStartime] = useState(
    dateFormat(
      moment().subtract(parseInt(moment().format().slice(19, 22)) + 5, "hours")
    )
      .format()
      .replace(
        dateFormat(
          moment().subtract(
            parseInt(moment().format().slice(19, 22)) + 5,
            "hours"
          )
        )
          .format()
          .slice(19, 22),
        "-05"
      )
  );
  const [endtime, setEndtime] = useState(
    dateFormat(
      moment()
        .subtract(parseInt(moment().format().slice(19, 22)) + 5, "hours")
        .add(15, "minutes")
    )
      .format()
      .replace(
        dateFormat(
          moment()
            .subtract(parseInt(moment().format().slice(19, 22)) + 5, "hours")
            .add(15, "minutes")
        )
          .format()
          .slice(19, 22),
        "-05"
      )
  );

  /*
  hook that calculates the time difference between dates, useful to calculate the rental period that will be displayed in the shopping cart
  */
  const [timedifference, setTimeDifference] = useState(15);

  useEffect(() => {
    //hook that controls user geolocation
    navigator.geolocation.getCurrentPosition(
      function (position) {
        const currentLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        const zoom = 13;
        setState({ ...state, currentLocation, zoom });
      },
      function (error) {
        console.log(error);
        setState(state);
      },
      { enableHighAccuracy: true }
    );

    get_parkings(startime, endtime);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCallback = (start, end) => {
    /*
    this arrow function control the onClick of the datepicker used for parking search by date range
     */
    let startime = start.format();
    let endtime = end.format();
    let timedifference = end.diff(start, "minutes");
    setStartime(startime.replace(startime.slice(19, 22), "-05"));
    setEndtime(endtime.replace(endtime.slice(19, 22), "-05"));
    setTimeDifference(timedifference);
    get_parkings(startime, endtime);
  };

  let map = (
    <GeoMap
      state={state}
      parkings={parkings}
      location={location}
      startime={startime}
      endtime={endtime}
      timedifference={timedifference}
      user={user}
    />
  );

  return (
    <>
      <DateRangePicker
        onCallback={handleCallback}
        initialSettings={{
          timePicker: true,
          timePickerIncrement: 15,
          maxSpan: { days: 7 },
          minDate: dateFormat(
            moment().subtract(
              parseInt(moment().format().slice(19, 22)) + 5,
              "hours"
            )
          )
            .startOf("minute")
            .toDate(),
          startDate: dateFormat(
            moment().subtract(
              parseInt(moment().format().slice(19, 22)) + 5,
              "hours"
            )
          )
            .startOf("minute")
            .toDate(),
          maxDate: dateFormat(
            moment().subtract(
              parseInt(moment().format().slice(19, 22)) + 5,
              "hours"
            )
          )
            .add(15, "minutes")
            .startOf("minute")
            .add(7, "day")
            .toDate(),
          endDate: dateFormat(
            moment().subtract(
              parseInt(moment().format().slice(19, 22)) + 5,
              "hours"
            )
          )
            .add(15, "minutes")
            .startOf("minute")
            .toDate(),
          locale: {
            format: "MMM D h:mm A",
            applyLabel: "Buscar",
            cancelLabel: "Cancelar",
          },
          linkedCalendars: false,
          autoUpdateInput: true,
        }}
      >
        <input className="form-control" type="text" />
      </DateRangePicker>
      {map}
    </>
  );
};

const mapStatetoProps = (state) => ({
  parkings: state.parkings.parkings,
});

export default connect(mapStatetoProps, { get_parkings })(MapView);
