import React, { useState, useEffect } from "react";

/*
library that allows configuring the description of the current 
visible page in the browser
*/
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

// library that allows you to build requests to the database
import axios from "axios";

// library that generates alerts within the shopping cart
import { ToastContainer, toast } from "react-toastify";

import TransactionDescription from "../components/TransactionDescription";

import { STORAGE_PRODUCTS_CART } from "../actions/types";

const RedirectPage = (props) => {
  // console.log(props.match.params.id);
  // hook that returns the info of the transaction performed
  const [transactionDescription, setTransactionDescription] = useState([]);

  useEffect(() => {
    localStorage.removeItem(STORAGE_PRODUCTS_CART);
    /* 
      This hook control description of the transaction made by the customer
    */
    const transactionid = props.match.params.id;

    axios.defaults.headers = {
      "Content-Type": "application/json",
    };

    const getTransactionDescription = async () => {
      /* 
      Gets the query of pending of the transaction made by the customer to be displayed on Redirect Page
      */
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/parkings/searchdescriptiontransaction`,
          {
            transactionid,
          }
        );
        setTransactionDescription(res.data);
        toast.success(
          `Ten a la mano tu tiquete de compra al llegar al parqueadero. En tu sección MIS RESERVACIONES queda registrada también esta información`
        );
      } catch (err) {
        console.log(err);
      }
    };
    getTransactionDescription();
  }, [props.match.params.id]);

  let ResumeTransaction = (
    <TransactionDescription transactionDescription={transactionDescription} />
  );
  return (
    <>
      <Helmet>
        <title>Plataforma de Parqueo - Resumen de la Transacción</title>
        <meta name="description" content="pqrs" />
      </Helmet>
      <div className="container mt-5 d-flex flex-column align-items-center">
        <h2 className="display-3 mt-5">Transacción finalizada!!</h2>
        <div className="card card-body mt-5 text-center">
          {ResumeTransaction}
        </div>
        <h3 className="display-3 mt-5">Gracias por elegirnos</h3>
        <p className="text-muted">
          Esperamos que hayas disfrutado de tu experiencia comprando en
          YoParqueo.co
        </p>
        <Link to="/" className="btn btn-success mt-5">
          Volver al sitio
        </Link>

        <ToastContainer
          position="bottom-left"
          autoClose={10000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover={false}
        />
      </div>
    </>
  );
};

export default RedirectPage;
