//h.o.c.s Higher Order Components
import React, { useEffect } from "react"; //NEW!
import Navbar from "../components/Navbar";
import Alert from "../components/Alert";

import { connect } from "react-redux"; //NEW!
import { check_authenticated, load_user, refresh } from "../actions/auth";
// import { load_user } from "../actions/profile"; // New!. Avoid that the username lost during refresh

const Layout = ({ children, check_authenticated, load_user, refresh }) => {
  useEffect(() => {
    refresh();
    check_authenticated();
    load_user();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // app rendering scheme
  return (
    <div>
      <Navbar />

      <div
        data-bs-spy="scroll"
        data-bs-target="#navbar"
        data-bs-offset="60"
        className="container card card-body mt-4 mb-4"
        tabIndex="0"
        style={{ paddingTop: "60px" }}
      >
        {children}
        <Alert />
      </div>
    </div>
  );
};

export default connect(null, {
  check_authenticated,
  load_user,
  refresh,
})(Layout);

// New!. profile.js in src/actions and /reducers to avoid that the username lost during refresh
// New!. PrivateRoute.js must be in hocs
