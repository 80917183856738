import React, { useState } from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { reset_password } from "../actions/auth";

// library that configures page loading animation
import Loader from "react-loader-spinner";

const ResetPassword = ({ loading, reset_password }) => {
  const [requestSent, setRequestSent] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
  });

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    reset_password(email);
    setRequestSent(true);
  };

  if (requestSent && loading) return <Redirect to="/#signup" />;

  return (
    <div className="container mt-5">
      <h1 className="mb-5">Petición para la recuperación de contraseña</h1>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <i className="fas fa-at">Correo Electrónico</i>
          <input
            className="form-control mb-3"
            name="email"
            onChange={(e) => onChange(e)}
            value={email}
            placeholder="Tu Correo Electrónico"
            autoFocus
            required
            type="email"
          />
        </div>
        {loading ? (
          <div className="mt-3 d-flex justify-content-center align-items-center flex-column">
            <Loader type="Oval" color="#424242" height={50} width={50} />
          </div>
        ) : (
          <button className="btn btn-primary" type="submit">
            Cambiar contraseña
          </button>
        )}
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
});

export default connect(mapStateToProps, { reset_password })(ResetPassword);
