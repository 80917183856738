import React from "react";

export const CustomerTerms = () => (
  <>
    <div className="modal-header">
      <h5 className="modal-title" id="TermsConditionsModalLabel">
        <b>
          Términos de uso, condiciones de la reserva de espacios de parqueaderos
          por medio del sistema yoParqueo.co
        </b>
      </h5>
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
      <p>
        El USUARIO acepta irrevocablemente las siguientes condiciones generales
        en la reserva de espacios de parquaderos por el sistema yoParqueo.co
      </p>
      <p>
        <b>Primero:</b> yoParqueo, es una marca registrada de propiedad de
        RiTech SAS, persona jurídica domiciliada en Cali, Colombia con NIT
        901467848. Cuyo objeto social, para efectos de las presentes
        condiciones, es la oferta y reserva de espacios de parquaderos, así como
        la comercialización de productos y servicios de terceras personas.
      </p>
      <p>
        <b>Segundo:</b> el CLIENTE es el usuario, previamente registrado en la
        página web www.yoparqueo.co, quien acepta haber anotado toda la
        información verdadera personal requerida, y es él/ella únicamente
        responsable por la información que no sea verdadera allí registrada.
        Quien suministre información o use su reserva con objetivos diferentes
        al de parquear su carro en el parqueadero donde ha reservado, será
        responsable en los términos del código penal colombiano.
      </p>
      <p>
        <b>Tercero:</b> El CLIENTE declara conocer que el hecho de reservar un
        parqueadero por medio del sistema yoParqueo le garantiza el espacio de
        parqueadero para la fecha, hora de llegada y parqueadero seleccionado.
        YoParqueo.co así como RiTech SAS no se responsabilizan por condiciones
        de seguridad, pagos por uso de parqueadero ni daños o perjuicios al
        vehículo del CLIENTE ni tampoco a terceros.
      </p>
      <p>
        <b>Cuarto:</b> Los pagos y compras realizadas por el sistema yoParqueo,
        están sujetas a la verificación, y aceptación de la tarjeta débito o
        crédito por parte del BANCO, o entidad financiera, por la que el cliente
        hace la compra así como a la verificación de los datos.
      </p>
      <p>
        <b>Quinto:</b> El CLIENTE acepta y conoce que la reserva de parqueadero
        tiene un costo que no incluye el valor por el tiempo de uso del
        parqueadero. Este costo por el concepto de reserva de un espacio de
        parqueadero le garantiza a EL CLIENTE que tiene un espacio para parquear
        su carro en el parqueadero seleccionado para la fecha y hora indicadas
        en el sistema yoParqueo, este costo de reserva puede ser variable
        dependiendo del parqueadero y de su ubicación y será pagado a
        yoParqueo.co
      </p>
      <p>
        <b>Sexto:</b> El CLIENTE acepta que los datos del parqueadero, fecha,
        hora, ubicación del parqueadero, han sido revisadas por él y la
        información ingresada al sistema yoParqueo.co es de su responsabilidad.
      </p>
      <p>Así mismo, EL CLIENTE acepta que:</p>
      <p>
        yoParqueo.co ni RiTech SAS, no están obligados a hacer devoluciones de
        dinero por errores de fechas, horas, valores registrados, selección de
        parqueaderos, ubicación del vehículo dentro del parqueadero
        (cubierto/descubierto, distancia hacia las puertas de acceso del
        parqueadero, seguridad, calidad de los parqueaderos, o en general causas
        ajenas a responsabilidades propias o de sus agentes.
      </p>
      <p>
        YoParqueo.co, no está obligado a hacer devoluciones de dinero en caso
        que el parqueadero deba ser cerrado por situaciones de orden publico, de
        fuerza mayor, caso fortuito o a raíz de situaciones de salud y/o
        calamidad pública y/o debido a enfermedades contagiosas y/o amenaza o
        miedo de enfermedad contagiosa, o por cualquier otra causa ajena a
        yoParqueo.co o que presente alguna modificación en las condiciones
        inicialmente anunciadas.
      </p>
      <p>
        <b>Séptimo:</b> El cliente acepta que la información registrada en el
        sistema yoParqueo.co, así como de las transacciones efectuadas son de
        propiedad de yoParqueo.co , quien está autorizado para dar usos
        comerciales sin afectar en ningún caso la intimidad y seguridad de los
        usuarios.
      </p>
      <p>
        <b>Octavo:</b> No existen cambios de fecha, horario ni parqueadero así
        como tampoco reembolsos ni cancelaciones de reserva de parqueadero una
        vez efectuado el pago de la reserva.
      </p>
      <p>
        <b>Noveno:</b> el CLIENTE acepta que toda reserva de parqueadero implica
        que si el espacio de parqueadero reservado no fue utilizado en la fecha
        y hora reservada, yoParqueo.co no se hace responsable y no habrá
        devolución de dinero.
      </p>
      <p>
        <b>Décimo:</b> Las compras efectuadas en la página web www.yoparqueo.co
        se entienden efectuadas en los términos de la legislación Colombiana, en
        particular la ley 527 de 1999; razón por la que todas las transacciones,
        consultas y compras se reputan firmadas electrónicamente por el usuario,
        quien se obliga en los términos legales en el negocio jurídico
        realizado.
      </p>
      <p>
        <b>Undécimo:</b> Cualquier diferencia surgida entre las partes será
        dirimida en derecho aplicando la legislación colombiana, el CLIENTE se
        obliga a hacer cualquier reclamación directamente a yoParqueo.co, y usar
        todos los métodos alternativos de solución de conflictos.
      </p>
      <p>
        <b>Duodécimo:</b> De conformidad con el Estatuto de la Ley del
        Consumidor, el Usuario podrá en casos de ventas no presenciales ejercer
        el derecho de retracto dentro de los términos y condiciones de la ley.
      </p>
      <p>
        Ante cualquier consulta, no dude en contactarnos&nbsp;
        <a href="/contact" className="tooltip-test" title="Tooltip">
          AQUÍ
        </a>
      </p>
      <p>
        <b>$revision 1.0 de 2021.06.24</b>
      </p>
    </div>
    <div className="modal-footer">
      <button
        type="button"
        className="btn btn-secondary"
        data-bs-dismiss="modal"
      >
        Entiendo y Acepto
      </button>
    </div>
  </>
);

export const OwnerTerms = () => (
  <>
    <div className="modal-header">
      <h5 className="modal-title" id="TermsConditionsModalLabel">
        <b>
          Términos de uso, condiciones de la puesta a disposición de un
          parqueadero por medio del sistema yoParqueo.co
        </b>
      </h5>
      <button
        type="button"
        className="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="modal-body">
      <p>
        El presente contrato se suscribe entre las partes: Propietario de
        parqueadero y la plataforma de reservas yoParqueo.co.
      </p>
      <p>
        El Propietario acepta irrevocablemente las siguientes condiciones
        generales en la reserva de espacios de parquaderos por medio de el
        sistema yoParqueo.co
      </p>
      <p>
        <b>Primero:</b> yoParqueo, es una marca registrada de propiedad de
        RiTech SAS, Nit 901467848, persona jurídica ubicada en Cali, Colombia.
        Cuyo objeto social, para efectos de las presentes condiciones, es la
        oferta y reserva de espacios de parquaderos, así como la
        comercialización de productos y servicios de terceras personas.
      </p>
      <p>
        <b>Segundo:</b> el PROPIETARIO es usuario, previamente registrado en la
        página web www.yoparqueo.co, quien acepta haber anotado toda la
        información verdadera personal requerida, y es él/ella únicamente
        responsable por la información que no sea verdadera allí registrada.
        Quien suministre información falsa o use los parqueaderos registrados
        con objetivos diferentes al de poner a disposición los parqueaderos en
        cantidad acordada previamente para los clientes de yoParqueo.co será
        responsable en los términos del código penal colombiano.
      </p>
      <p>
        <b>Tercero:</b> El PROPIETARIO pone a disposición de la plataforma
        yoParqueo, sus espacios de parqueadero para que los clientes de
        yoParqueo puedan reservar estos por medio de yoParqueo.co. El
        PROPIETARIO declara conocer que el hecho de reservar un parqueadero por
        medio del sistema yoParqueo lo compromete a garantizar el espacio de
        parqueadero para la fecha, hora de llegada y parqueadero seleccionado al
        cliente que ha confirmado su reserva. YoParqueo.co así como RiTech SAS
        no se responsabilizan por condiciones de seguridad, pagos por uso de
        parqueadero ni daños o perjuicios al vehículo del CLIENTE ni tampoco a
        terceros.
      </p>
      <p>
        <b>Cuarto:</b> Los pagos por concepto de uso del parqueadero del
        PROPIETARIO se efectuaran directamente en el parqueadero y seràn
        realizados por el cliente de yoParqueo que ha utilizado dicho
        parqueadero. Los pagos y compras realizadas por el sistema yoParqueo,
        están destinados a el mantenimiento y funcionamiento de la plataforma de
        yoParqueo.co y no constituyen pagos a El PROPIETARIO.
      </p>
      <p>
        <b>Quinto:</b> El PROPIETARIO acepta y conoce que la reserva de
        parqueadero tiene un costo que no incluye el valor por el tiempo de uso
        del parqueadero. Este costo por el concepto de reserva de un espacio de
        parqueadero obliga a EL PROPIETARIO a garantizar a EL CLIENTE que este
        ultimo tiene un espacio para parquear su carro en el parqueadero
        seleccionado para la fecha y hora indicadas en el sistema yoParqueo.co
      </p>
      <p>
        <b>Sexto:</b> El PROPIETARIO acepta que los datos del parqueadero,
        dirección, numero de espacios disponibles, fechas, horario, han sido
        revisadas por él y la información ingresada al sistema yoParqueo.co es
        de su responsabilidad.
      </p>
      <p>Así mismo, EL PROPIETARIO acepta que:</p>
      <p>
        yoParqueo.co ni RiTech SAS, no están obligados a hacer pagos de dinero
        ni indemnizaciones de perjuicios por daños o pérdidas ocasionadas por
        clientes a los espacios de parqueadero del PROPIETARIO, así como tampoco
        ningún pago de indemnización por pérdidas o daños sufridos a vehículos
        de los clientes de yoParqueo o de terceros durante y fuera del periodo
        de utilización del parqueadero.
      </p>
      <p>
        YoParqueo.co, no está obligado a hacer devoluciones de dinero o pagos de
        indemnización en caso que el parqueadero deba ser cerrado por
        situaciones de orden publico, de fuerza mayor, caso fortuito o a raíz de
        situaciones de salud y/o calamidad pública y/o debido a enfermedades
        contagiosa y/o amenaza o miedo de enfermedad contagiosa, o por cualquier
        otra causa ajena a yoParqueo.co o que presente alguna modificación en
        las condiciones inicialmente anunciadas.
      </p>
      <p>
        <b>Séptimo:</b> El PROPIETARIO acepta que la información registrada en
        el sistema yoParqueo.co, así como de las transacciones efectuadas son de
        propiedad de yoParqueo.co , quien está autorizado para dar usos
        comerciales sin afectar en ningún caso la intimidad y seguridad de los
        usuarios.
      </p>
      <p>
        <b>Octavo:</b> Para poder incluir un parqueadero en la plataforma
        yoParqueo, el PROPIETARIO acepta los presentes términos y condiciones.
      </p>
      <p>
        <b>Noveno:</b> La aceptación de los presentes términos y condiciones por
        parte del PROPIETARIO en la página web www.yoparqueo.co, se entiende
        efectuada en los términos de la legislación Colombiana, en particular la
        ley 527 de 1999; razón por la que todas las transacciones, consultas y
        compras se reputan firmadas electrónicamente por el PROPIETARIO, quien
        se obliga en los términos legales en el negocio jurídico realizado.
      </p>
      <p>
        <b>Décimo:</b> Los términos y condiciones pueden ser modificados y
        actualizados por yoParqueo.co o por Ritech SAS en todo momento sin
        necesidad expresa de consultar ni autorizacion por parte del
        PROPIETARIO.
      </p>
      <p>
        <b>Undécimo:</b> Cualquier diferencia surgida entre las partes será
        dirimida en derecho aplicando la legislación colombiana, el PROPIETARIO
        se obliga a hacer cualquier reclamación directamente a yoParqueo.co, y
        usar todos los métodos alternativos de solución de conflictos.
      </p>
      <p>
        <b>$revision 1.0 de 2021.06.24</b>
      </p>
    </div>
    <div className="modal-footer">
      <button
        type="button"
        className="btn btn-secondary"
        data-bs-dismiss="modal"
      >
        Entiendo y Acepto
      </button>
    </div>
  </>
);

// $revision 1.0 de 2021.06.24
