import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import { connect } from "react-redux";

/*special library that validates the phone number*/
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
// Country names in Spanish.
import es from "react-phone-number-input/locale/es";
/* 
library that generates a unique identification code for each alert built
 */
import { setAlert } from "../actions/alert";

/* 
  arrow function where send signup form data as post request to django and show signup authentication alerts
*/
import { signup } from "../actions/auth";

// library that imports terms & conditions
import { CustomerTerms, OwnerTerms } from "./Terms&Conditions";

// library that configures page loading animation
import Loader from "react-loader-spinner";

export const SignUp = ({ setAlert, signup, isAuthenticated, loading }) => {
  //user registration form
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    re_password: "",
    user_type: "Cliente",
    phone: "",
  });
  const [agree, setAgree] = useState(false);
  const { username, email, password, re_password, user_type } = formData;
  let { phone } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  // const handlePhoneChange = (value) => {
  //   setFormData({ ...formData, phone: value });
  // };
  const handlePhoneChange = (value, country, e) => {
    setFormData({
      ...formData,
      phone: value,
    });
  };

  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setAgree(!agree);
    // Don't miss the exclamation mark
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (password.length < 5 || re_password.length < 5)
      setAlert("La contraseña debe tener como minimo 4 caracteres", "danger");
    if (password !== re_password)
      setAlert("Las contraseñas no coinciden", "danger");
    if (user_type !== "Cliente" && phone === "")
      setAlert(
        "Como " + user_type + " debes ingresar un número de Whatsapp",
        "danger"
      );
    else signup({ username, email, phone, password, re_password, user_type });
  };

  if (isAuthenticated)
    if (user_type === "Propietario de Parqueadero")
      return <Redirect to="/ownermenu" />;
    else return <Redirect to="/customermenu" />;

  return (
    <div className="col-md-6 m-auto">
      <div className="card card-body mt-5">
        <p className="text-center">Crea tu Cuenta</p>
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <label htmlFor="user_type">
              ¿Qué tipo de usuario eres?<i className="fas fa-users-cog"></i>
            </label>
            <select
              className="form-control"
              name="user_type"
              onChange={(e) => onChange(e)}
              value={user_type}
            >
              <option value="Propietario de Parqueadero">
                Propietario de Parqueadero
              </option>
              <option value="Cliente">Cliente</option>
            </select>
          </div>
          <div className="form-group">
            <i className="fas fa-user">Nickname (* máx. 20 caracteres)</i>
            <input
              className="form-control"
              name="username"
              onChange={(e) => onChange(e)}
              placeholder="Nickname"
              autoFocus
              required
              type="text"
            />
          </div>
          <div className="form-group">
            <i className="fas fa-at">Correo Electrónico (*)</i>
            <input
              className="form-control"
              name="email"
              onChange={(e) => onChange(e)}
              placeholder="ejemplo@correo.com"
              required
              type="email"
            />
          </div>
          <div className="form-group">
            <i className="fab fa-whatsapp">
              <b>
                Whatsapp&nbsp;
                {user_type === "Propietario de Parqueadero" ? <b>(*)</b> : ""}
              </b>
            </i>
            <PhoneInput
              labels={es}
              placeholder="3xx xxx xx xx"
              defaultCountry="CO"
              countries={["CO", "FR"]}
              initialValueFormat="national"
              onChange={(value, country, e) =>
                handlePhoneChange(value, country, e)
              }
            />
          </div>
          <div className="form-group">
            <i className="fas fa-lock-open">Contraseña (*)</i>
            <input
              className="form-control"
              name="password"
              onChange={(e) => onChange(e)}
              placeholder="*****"
              type="password"
              // minLength="5"
            />
          </div>
          <div className="form-group">
            <i className="fas fa-lock">Confirmar Contraseña</i>
            <input
              className="form-control"
              name="re_password"
              onChange={(e) => onChange(e)}
              placeholder="*****"
              type="password"
              // minLength="5"
            />
          </div>
          <p className="d-flex flex-row-reverse">(*) Campos obligatorios</p>
          <div>
            <input type="checkbox" id="agree" onChange={checkboxHandler} />
            <label htmlFor="agree">
              Acepto haber leído los&nbsp;
              <a href="#TermsConditionsModal" data-bs-toggle="modal">
                términos de uso y condiciones
              </a>
            </label>
          </div>

          <div
            className="modal fade"
            id="TermsConditionsModal"
            tabIndex="-1"
            aria-labelledby="TermsConditionsModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-scrollable">
              <div className="modal-content">
                {user_type === "Cliente" ? <CustomerTerms /> : <OwnerTerms />}
              </div>
            </div>
          </div>
          {loading ? (
            <div className="mt-3 d-flex justify-content-center align-items-center">
              <Loader type="Oval" color="#424242" height={50} width={50} />
            </div>
          ) : (
            <div className="d-grid">
              <button disabled={!agree} className="btn btn-primary">
                Registrar
              </button>
            </div>
          )}
        </form>
        {/* <p>{JSON.stringify(formData)}</p> */}
        <p>
          ¿Ya tienes una cuenta?&nbsp;
          <Link to="login">Inicia Sesión</Link>
        </p>
      </div>
    </div>
  );
};

SignUp.propTypes = {
  setAlert: PropTypes.func.isRequired,
  signup: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, { setAlert, signup })(SignUp);
