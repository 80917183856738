// library that allows you to build requests to the database
import axios from "axios";

import { GET_PARKINGS_SUCCESS, GET_PARKINGS_FAIL } from "./types";

export const get_parkings = (startime, endtime) => async (dispatch) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/parkings/get-parkings/${startime}/${endtime}`,
      config
    );

    if (!res.data.error) {
      dispatch({
        type: GET_PARKINGS_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_PARKINGS_FAIL,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_PARKINGS_FAIL,
    });
  }
};
