import React from "react";
import { Link } from "react-router-dom";
export const notFound = () => (
  <div className="container mt-5 d-flex flex-column align-items-center">
    <h1 className="display-3 mt-5">404 No Encontrado</h1>
    <p className="text-muted">
      El enlace que solicitaste no existe en nuestra aplicación
    </p>
    <Link to="/" className="btn btn-success mt-5">
      Volver al sitio
    </Link>
  </div>
);

export default notFound;
