import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { get_locations } from "../../actions/locations";

//components
import MapView from "./MapView/MapView";

export const ParkingMap = ({ locations, get_locations, user }) => {
  const [formData, setFormData] = useState({
    id: "",
    latitude: "4",
    longitude: "-73",
    name: "",
    zoom: "5",
  });
  const [departmentId, setDepartmentId] = useState(0);

  useEffect(() => {
    get_locations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeDepartment = (e) => {
    setDepartmentId(e.target.value);
    setFormData(
      locations.filter(
        (department) => department.id === parseInt(e.target.value)
      )[0]
    );
  };

  const onChangeCity = (e) => {
    setFormData(
      locations
        .filter((department) => department.id === parseInt(departmentId))
        .map((city) => {
          return city.cities.filter(
            (ide) => ide.id === parseInt(e.target.value)
          );
        })[0][0]
    );
  };

  return (
    <div>
      <div className="d-grid">
        <button
          className="btn btn-primary fas fa-map-pin mb-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseHome"
          aria-expanded="false"
          aria-controls="collapseHome"
        >
          Buscar por Ciudad
        </button>
      </div>

      <div className="collapse" id="collapseHome">
        <div className="card card-body m-auto">
          <form>
            <div className="form-group">
              <select
                className="form-control"
                name="dpt_id"
                onChange={onChangeDepartment}
              >
                <option defaultValue="">Selecciona un departamento</option>
                {locations &&
                  locations !== null &&
                  locations !== undefined &&
                  locations.map((location) => {
                    return (
                      <Fragment key={location.id}>
                        <option value={location.id}>{location.name}</option>
                      </Fragment>
                    );
                  })}
              </select>
            </div>
            <div className="form-group">
              <select
                className="form-control"
                name="city_id"
                onChange={onChangeCity}
              >
                <option defaultValue="">Selecciona una ciudad</option>
                {locations &&
                  locations !== null &&
                  locations !== undefined &&
                  locations.map((location) => {
                    let filterCity = location.cities.filter(
                      (city) => city.parent === parseInt(departmentId)
                    );
                    return filterCity.map((city) => {
                      return (
                        <Fragment key={city.id}>
                          <option value={city.id}>{city.name}</option>
                        </Fragment>
                      );
                    });
                  })}
              </select>
            </div>

            {/* {loading ? (
                <div>
                  <Loader type="Oval" color="#424242" />
                </div>
              ) : (
                <></>
              )} */}
          </form>
        </div>
      </div>

      <MapView location={formData} user={user} />
    </div>
  );
};

const mapStatetoProps = (state) => ({
  locations: state.locations.locations,
});

export default connect(mapStatetoProps, { get_locations })(ParkingMap);
