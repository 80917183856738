//library that displays custom icons on the map
import L from "leaflet";

export const ParkingLocation = L.icon({
  iconUrl: require("../../../assets/park.svg").default,
  iconRetinaUrl: require("../../../assets/park.svg").default,
  shadowUrl: null,
  shadowAnchor: null,
  shadowSize: null,
  iconSize: [35, 35],
  className: "Leaflet-parking-icon",
});

export const UserLocation = L.icon({
  iconUrl: require("../../../assets/car.svg").default,
  iconRetinaUrl: require("../../../assets/car.svg").default,
  shadowUrl: null,
  shadowAnchor: null,
  shadowSize: null,
  iconSize: [35, 35],
  className: "Leaflet-parking-icon",
});

export const SiteLocation = L.icon({
  iconUrl: require("../../../assets/pin.svg").default,
  iconRetinaUrl: require("../../../assets/pin.svg").default,
  shadowUrl: null,
  shadowAnchor: null,
  shadowSize: null,
  iconSize: [35, 35],
  className: "Leaflet-parking-icon",
});
